import React from "react"
import { getRecentPages } from "../../services/account-services"
import { isLoggedIn } from "../../services/auth"

const RecentViews = () => {
    var recentPages = []
    recentPages = getRecentPages()
    const renderDesigns = (design) => {
        var imagePath = design.imagePath
       return design.Floorplans.map((item, fidx) => {  
            var floorplan = item.UndigitalFloorplan
            var pageUrl = '/explore/home-designs/' + design.urlSegment + '/?vid=' + floorplan.objectID
            if(floorplan.groundFloorImagePath){
                imagePath = floorplan.groundFloorImagePath
            }
            return(
            <div key={`page-f-${fidx}`} className="col-4 col-md-4 col-lg-3 col-xl-2">
              <div className="grid-item grid-recent">
                <a href={pageUrl} className="grid-item-inner default-crop">
                  <img src={imagePath} />
                  <div className="grid-caption">
                    <h4>{design.name}</h4>
                    <div className="grid-bottom">
                      {design.variant && (
                        <p className="variant-name">{design.variant.name}</p>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            )
        })
    }
    
    return (
    <>
      {recentPages.length > 0 && (
        <div className="board-page-title small">
          <div className="row align-items-center">
            <div className="col-md">
              <h3 className="product-name-title d-flex align-items-center">
                Recently Viewed
              </h3>
            </div>
          </div>
        </div>
        )}
        {recentPages.length > 0 && (
        <div className="row recent-view">
          {recentPages.map((page, idx) => {
            let imagePath = ''
            let pageUrl = ''
            if(page.className == 'HomeDesign'){
                if(page.variant){
                    imagePath = page.variant.image
                }else{
                    imagePath = page.imagePath
                }
                pageUrl = '/explore/home-designs/' + page.urlSegment
                if(page.variant){
                    pageUrl += '/?vid=' + page.variant.objectID
                }
                return <div key={`page-${idx}`} className="col-4 col-md-4 col-lg-3 col-xl-2">
                  <div className="grid-item grid-recent">
                    <a href={pageUrl} className="grid-item-inner default-crop">
                      <img src={imagePath} />
                      <div className="grid-caption">
                        <h4>{page.name}</h4>
                        <div className="grid-bottom">
                          {page.variant && (
                            <p className="variant-name">{page.variant.name}</p>
                          )}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
            }else if(page.className == 'Selection'){
                imagePath = page.imagePath
                pageUrl = '/explore/style/' + page.urlSegment + '/'
                if(page.variant){
                    pageUrl += '/?vid=' + page.variant.objectID
                }
                  return <div key={`page-${idx}`} className="col-4 col-md-4 col-lg-3 col-xl-2">
                    <div className="grid-item grid-recent">
                      <a href={pageUrl} className="grid-item-inner default-crop">
                        <img src={imagePath} />
                        <div className="grid-caption">
                          <h4>{page.name}</h4>
                        </div>
                      </a>
                    </div>
                  </div>
            }else{
                if(typeof page.FeaturedVariation === 'undefined') return false
                switch (page.className) {
                    case 'Cabinetry':
                        if(page.variant){
                            imagePath = page.variant.featureImagePath
                        }else{
                            imagePath = page.FeaturedVariation.UndigitalCabinetryVariation.featureImagePath
                        }
                      pageUrl = '/explore/cabinetry/' + page.urlSegment + '/'
                      break
                    case 'Facade':
                      if(page.variant){
                            imagePath = page.variant.featureImagePath
                        }else{
                            imagePath = page.FeaturedVariation.UndigitalFacadeVariation.featureImagePath
                        }
                      pageUrl = '/explore/facades/' + page.urlSegment + '/'
                      break
                    case 'FinishesFixtures':
                        if(page.variant){
                            imagePath = page.variant.featureProductImagePath
                        }else{
                            imagePath = page.FeaturedVariation.UndigitalFinishesFixturesVariation.featureProductImagePath
                        }
                      pageUrl = '/explore/finishes-fixtures/' + page.urlSegment + '/'
                      break
                    case 'Furniture':                      
                      if(page.variant){
                            imagePath = page.variant.productImagePath
                        }else{
                            imagePath = page.FeaturedVariation.UndigitalFurnitureVariation.productImagePath
                        }
                      pageUrl = '/explore/furniture/' + page.urlSegment + '/'
                      break
                    default:
                      break
                }
                if(page.variant){
                    pageUrl += '?vid=' + page.variant.objectID
                }
                return <div key={`page-${idx}`} className="col-4 col-md-4 col-lg-3 col-xl-2">
                  <div className="grid-item grid-recent">
                    <a href={pageUrl} className="grid-item-inner default-crop">
                      <img src={imagePath} />
                      <div className="grid-caption">
                        <h4>{page.name}</h4>
                        {page.variant && (
                        <div className="grid-bottom">                          
                            <p className="variant-name">{page.variant.name}</p>                          
                        </div>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
            }
          })}
        </div>
        )}
    </>
    )
}

export default RecentViews
